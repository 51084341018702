<template>
    <div style="height:90vh; overflow-y:auto;"> <!--91vh-->
        <el-tabs  :animated="false" style="background:#f7f8f9;padding-left:15px;  " v-model="activeName"  >
            <el-tab-pane :name="item.TAG" v-for="(item,index) in menuArr" :key="index" style="padding-left:1px;" > 
                <span slot="label"> {{item.CNAME }}
                    <Divider v-if="index==0" type="vertical" style=" background-color:#409eff"/> 
                </span>
                 <!-- 信息发布-->
                <div v-if="item.TAG=='TAB4'" style="background:#f5f5f7;">
                    <div style="line-height:35px;border-left:3px solid #409eff;background:#67c23a;color:white ">
                        <span style="margin-left:10px;padding-right:20px;">
                            <i class="web-font" :style="{'font-size':'14px','color':'#fff'}"> 第一步、发布信息、文章、博客等，标题列表</i>
                        </span> 
                        <el-button type="primary" size="mini" @click="addNew" icon="iconfont icon-xinzeng" style="padding-left:20px;"> 【新增记录】 </el-button> 
                        <el-button type="primary" size="mini" @click="impExl" icon="iconfont icon-daorubiaodan"> 【批量导入】 </el-button> 
                        <el-select v-model="selparam" size="small"    style="width:140px; " >
                            <span v-for="item in newCol" :key='item.key'>
                                <el-option :label="item.title" :value="item.key" v-if="item.THIDE=='N'"></el-option>
                            </span>
                        </el-select>
                        <el-input  clearable  v-model="txtparam" @keyup.enter.native="getNewList" size="small" style="width:350px; height:30px;">
                            <el-select v-model="oper" slot="prepend"  style="width:100px; " >
                                <span v-for="item in operArr" :key='item.IDSEQ'>
                                    <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                                </span>
                            </el-select>
                            <el-button @click="getNewList" slot="append" icon="el-icon-search"></el-button>
                        </el-input> 
                    </div>
                    <div>
                    <el-table  :data="newData" 
                                border                            
                                disabled-hover
                                style="overflow:auto;margin-top:1px;"
                                size="small"
                                ref="refjoin"
                                :row-key="getRowKey"
                                @row-click="newClick"
                                key="keyjoin"
                                height="300" 
                                highlight-current-row>
                            <el-table-column   v-for="(item ,index) in newCol.filter(item=>item.THIDE=='N')" :key="index"
                                :prop="item.key"
                                :label="item.title"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                :sortable ="item.key!=='RN'&&item.EDCSS!=='checkbox'"
                                :width="item.width">
                                <template slot-scope="scope" >
                                    <el-checkbox v-if=" item.EDCSS=='checkbox' " disabled false-label="" true-label="Y" v-model="scope.row[item.key]" >&nbsp;</el-checkbox>
                                    <span v-else v-text="scope.row[item.key]"></span>
                                </template>
                            </el-table-column>  
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="180"
                            v-if="selectedRow"> <!--billy加的这一行-->
                        
                            <template slot-scope="scope">
                                <el-button size="small" v-if="scope.row['ISTOP']=='N'"  type="text" icon="iconfont icon-shenhe" @click="stfgRec(scope.row,scope.$index,'Y')">审核</el-button>
                                <el-button size="small" v-if="scope.row['ISTOP']=='Y'"  type="text" icon="iconfont icon-fanshenhe" @click="stfgRec(scope.row,scope.$index,'N')">弃审</el-button>
                                <el-button size="small"   type="text" icon="el-icon-edit" @click="newEdit(scope.row,scope.$index)">编辑</el-button>
                                <el-button size="small"   type="text" icon="el-icon-delete" @click="newDel(scope.row,scope.$index )">删除</el-button>
                            </template>
                        </el-table-column>                   
                    </el-table> 
                    </div>
                    <!-- 分页查询 -->
                    <div style="display:flex; align-items:center;line-height:30px;height:35px; padding-left:10px;background:white;font-size:12px" >              
                        <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
                    </div>
                    <div style="display:flex">
                        <!--中文版正文编辑器-->        
                        <div style="width:50%;padding-left:3px;">
                            <div style="line-height:30px;border-left:3px solid #409eff;background:#8d3ac2;margin-top:10px; color:white">
                                <span style="margin-left:15px;padding-right:30px;">第二步、中文版 正文编辑 + 配图3张 </span>                                
                                <span style="margin-left:20px;color:#fff;font-size:12px">提示：查询大对象字段速度有点慢，请务必耐心 ！</span>
                                <el-button type="primary" size="mini" @click="newCN" icon="iconfont icon-baocun" style="padding-left:30px;"> 保存中文正文 </el-button>
                            </div>
                            <div style="margin-top:3px;" v-if="isShow">
                                <tinymce id="refcn" v-model="newCHtml" height="400"  ></tinymce>
                            </div>
                        </div>
                        <!--英文版正文编辑器--> 
                        <div style="width:50%;padding-right:2px;">
                            <div style="line-height:30px;border-left:3px solid #409eff;background:#c2733a;margin-top:10px; color:white">
                                <span style="Color:#004b98;margin-left:15px;padding-right:30px;">第三步、ENGLISH英文版 正文编辑 + 配图3张</span>                                
                                <span style="margin-left:20px;color:#fff;font-size:12px">可复制中文版图片！共用图片！</span>
                                <el-button type="primary" size="mini" @click="newRec" icon="iconfont icon-baocun" style="padding-left:30px;" > 保存英文正文 </el-button>
                            </div>
                            <div style="margin-top:3px;" v-if="isShow">
                                <tinymce id="refnew" v-model="newHtml" height="400"  ></tinymce>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- 企业简介 -->
                <div v-if="item.TAG=='TAB1'" style=" background:#f5f5f7;padding-left:15px;">
                    <div style="line-height:30px;border-left:3px solid #409eff;background:#f0f8f9; ">
                        <span style="margin-left:30px;padding-right:30px;">一、视频文件(大小：1270 x 600)</span>
                        <span style="margin-left:20px">
                        <Upload
                            ref="upload"
                            name="upfile"
                            :data="uploadData"
                            :show-upload-list="false"
                            :before-upload="beforeImgFile"
                            :on-success="successImgFile"
                            :on-progress="handleProgress"
                            :accept="Accept"                      
                            :format="['mp4' ]"
                            :max-size="102400"
                            :on-format-error="videoFormat"
                            :on-exceeded-size="videoMaxSize"
                            :action="uploadUrl"
                            style="display: inline-block;width:90px; ">                           
                            <el-link :underline="false"><i class="iconfont icon-shangchuan" style="color:#023974;"></i>上传视频</el-link>                                                       
                        </Upload>
                        </span> 
                    </div>
                    <div class="video-small">
                        <video-player   class="video-player vjs-custom-skin custom-skin" 
                                        ref="videoPlayer" 
                                        :playsline="false" 
                                        :options="videoConfig">
                        </video-player>
                    </div>
                    <div style="line-height:30px;border-left:3px solid #409eff;background:#f0f8f9;margin-top:10px; ">
                         <span style="margin-left:30px;font-weight:bold;">二、企业简介</span>
                         <el-link @click="saveRec" :underline="false" style="margin-left:20px;padding-left:30px;color:#023974;" ><i class="iconfont icon-baocun"></i>保存内容</el-link>    
                    </div>
                    <div style="margin-top:3px;" v-if="isShow">
                        <tinymce ref="editor" v-model="tinymceHtml" height="500"  ></tinymce>
                    </div>
                    <div style="line-height:30px;border-left:3px solid #409eff;background:#f0f8f9;margin-top:10px; ">
                         <span style="margin-left:30px;font-weight:bold;">三、企业图片(图片大小：1200 x 600)</span>
                         <span style="margin-left:20px;padding-right:30px;">
                         <Upload
                            ref="upimg"
                            name="upfile"
                            :data="uploadData"
                            :show-upload-list="false"
                            :before-upload="beforeImgFile"
                            :on-success="okImgFile"
                            :on-progress="handleProgress"
                            :accept="imgAct"                      
                            :format="['jpg','jpeg','png','gif','webp' ]"
                            :max-size="4096"
                            :on-format-error="imgFormat"
                            :on-exceeded-size="imgMaxSize"
                            :action="uploadUrl"
                            style="display: inline-block;width:90px; ">                           
                            <el-link :underline="false"><i class="iconfont icon-shangchuan" style="color:#023974;"></i>上传图片</el-link>                                                       
                        </Upload>
                         </span>
                    </div>
                    <el-image :src="imgPath" fit="scale-down" style="width:1260px ;height:600px"></el-image>    
                </div>
                <!-- 资质荣誉 -->
                <div v-if="item.TAG=='TAB2'" style="background:#f5f5f7;">
                    <div style="line-height:35px;border-left:3px solid #409eff;background:#f0f8f9; ">
                        <span style="margin-left:10px">
                            <i class="web-font" :style="{'font-size':'16px','color':'#409eff'}"> 资质荣誉 (图片大小：280 x 260)</i>
                        </span> 
                        【<el-link :underline="false" @click="addCert('CERT')"><i class="iconfont icon-xinzeng" style="font-size:14px;color:#409eff">新增记录</i></el-link>】 
                    </div>
                    <div>
                    <el-table  :data="tbData" 
                                border                            
                                disabled-hover
                                style="overflow:auto;margin-top:1px;"
                                size="small"
                                key="reftab"
                                height="300" 
                                highlight-current-row>
                        <template v-for="(item ,index) in tabCol">
                            <el-table-column   v-if="item.THIDE==='N' " :key="index"
                                :prop="item.key"
                                :label="item.title"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                sortable 
                                :width="item.width">
                            </el-table-column>  
                        </template>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-button size="small"   type="text" icon="el-icon-edit" @click="tbEdit(scope.row,'CERT')">编辑</el-button>
                                <el-button size="small"   type="text" icon="el-icon-delete" @click="tbDel(scope.row,scope.$index,'CERT')">删除</el-button>
                            </template>
                        </el-table-column>                   
                    </el-table> 
                    </div>
                    <div style="display:flex;display:flex;flex-wrap:wrap;justify-content:space-between;width:1270px" > 
                        <div  v-for="(item,index) in tbData" :key="index" style="margin-top:15px;width:280px;height:300px;"> 
                            <div  style="display:flex;flex-direction:column;">
                                <div  style=" width:280px;height:260px;overflow:hidden">
                                    <el-image :src="item.IMGPATH" class="imgSize" fit="fill"></el-image> 
                                </div> 
                                <div class="img-div">{{ item.CNAME }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 知识产权-->
                <div v-if="item.TAG=='TAB3'" style="background:white;">
                    <div style="line-height:35px;border-left:3px solid #409eff;background:#f0f8f9; ">
                        <span style="margin-left:10px">
                            <i class="web-font" :style="{'font-size':'16px','color':'#409eff'}"> 知识产权 (图片大小：280 x 340)</i>
                        </span> 
                        【<el-link :underline="false" @click="addCert('KNOW')"><i class="iconfont icon-xinzeng" style="font-size:14px;color:#409eff">新增记录</i></el-link>】 
                    </div>
                    <div>
                    <el-table  :data="knowData" 
                                border                            
                                disabled-hover
                                style="overflow:auto;margin-top:1px;"
                                size="small"
                                key="refknow"
                                :row-key="getRowKey"
                                height="300" 
                                highlight-current-row>
                        <template v-for="(item ,index) in tabCol">
                            <el-table-column   v-if="item.THIDE==='N' " :key="index"
                                :prop="item.key"
                                :label="item.title"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                sortable 
                                :width="item.width">
                            </el-table-column>  
                        </template>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-button size="small"   type="text" icon="el-icon-edit" @click="tbEdit(scope.row,'KNOW')">编辑</el-button>
                                <el-button size="small"   type="text" icon="el-icon-delete" @click="tbDel(scope.row,scope.$index,'KNOW')">删除</el-button>
                            </template>
                        </el-table-column>                   
                    </el-table> 
                    </div>
                    <div style="display:flex;display:flex;flex-wrap:wrap;justify-content:space-between;width:1270px" > 
                        <div  v-for="(item,index) in knowData" :key="index" style="margin-top:15px;width:280px;height:380px;"> 
                            <div  style="display:flex;flex-direction:column;border:2px solid #409eff">
                                <div  style=" width:276px;height:340px;overflow:hidden">
                                    <el-image :src="item.IMGPATH" class="knowSize"  ></el-image> 
                                </div> 
                                <div class="know-div">{{ item.CNAME }}</div>
                            </div>
                        </div>
                    </div>
                </div>
               <!-- 加入我们-->
                <div v-if="item.TAG=='TAB6'" style="background:white;">
                    <div style="line-height:35px;border-left:3px solid #409eff;background:#f0f8f9; ">
                        <span style="margin-left:10px">
                            <i class="web-font" :style="{'font-size':'16px','color':'#409eff'}"> 发布招聘信息</i>
                        </span> 
                        【<el-link :underline="false" @click="addJob"><i class="iconfont icon-xinzeng" style="font-size:14px;color:#409eff">新增记录</i></el-link>】 
                    </div>
                    <div> 
                    <el-table  :data="joinData" 
                                border                            
                                disabled-hover
                                style="overflow:auto;margin-top:1px;"
                                size="small"
                                ref="refjoin"
                                :row-key="getRowKey"
                                @row-click="joinClick"
                                key="keyjoin"
                                height="300" 
                                highlight-current-row>
                        <template v-for="(item ,index) in joinCol">
                            <el-table-column   v-if="item.THIDE==='N' " :key="index"
                                :prop="item.key"
                                :label="item.title"
                                show-overflow-tooltip
                                :align="item.align"                                  
                                sortable 
                                :width="item.width">
                            </el-table-column>  
                        </template>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            align="center"
                            width="140">
                            <template slot-scope="scope">
                                <el-button size="small"   type="text" icon="el-icon-edit" @click="joinEdit(scope.row,scope.$index)">编辑</el-button>
                                <el-button size="small"   type="text" icon="el-icon-delete" @click="joinDel(scope.row,scope.$index )">删除</el-button>
                            </template>
                        </el-table-column>                   
                    </el-table> 
                    </div>
                    <div style="line-height:30px;border-left:3px solid #409eff;background:#f0f8f9;margin-top:10px; ">
                         <span style="margin-left:10px">二、职位详情要求</span>
                         <el-link @click="jobRec" :underline="false" style="margin-left:20px;" ><i class="iconfont icon-baocun"></i>保存内容</el-link>    
                    </div>
                    <div style="margin-top:3px;" v-if="isShow">
                        <tinymce id="jobedit" v-model="jobHtml" height="400"  ></tinymce>
                    </div>
                </div>
            </el-tab-pane>           
        </el-tabs>
        <!-- 预览图片 --> 
        <el-dialog :visible.sync="showViewer" width="950px" append-to-body :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'40px'}">
                <span style="line-height:35px;height:35px;font-size:16px;padding-left:5px;">图片预览</span>
            </div>
            <el-image fit="scale-down" :src="prevPath"  style="width:99%;height:400px;margin-top:5px;"></el-image> 
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:15px;">提示</span>
            </div>
            <div style=" margin:15px 10px">{{prompt}}</div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:5px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
        <!-- 资质荣誉编辑 -->
        <el-dialog v-dialogDrag :show-close="false" :visible.sync="certWin" width="1000px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">资质荣誉 </span>
            </div> 
            <Form :model="tbRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in tabCol" :key="index" >
                    <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="tbRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="tbRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="display:flex">
                    <div style=" margin-top:20px;">                   
                        <el-row>
                            <el-image  :src="tbRow['IMGPATH']" style="width:120px;height:120px;margin-right:20px;" ></el-image>
                        </el-row> 
                        <el-row > 
                            <el-link   :underline="false" @click="imgPreview('cert')"><i class="iconfont icon-chazhao" style="margin-left:90px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-left:20px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upload"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="certImgFile"
                                    :on-progress="handleProgress"
                                    :accept="imgAct"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="4096"
                                    :on-format-error="imgFormat"
                                    :on-exceeded-size="imgMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:90px; ">                           
                                    <div style="padding: 1px 0;width:120px;height:120px;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" ></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                    </div> 
                </div>
            </Form>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="certWin=false" style="margin-right:50px;">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun" @click="certSave"  style="margin-right:10px;">保存</el-button>
                </span>
            </div>
        </el-dialog>
        <!-- 招聘信息编辑 -->
        <el-dialog v-dialogDrag :visible.sync="joinWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">招聘信息 </span>
            </div> 
            <Form :model="joinRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in joinCol" :key="index" >
                    <FormItem  :label="item.NOTNULL=='Y'?'*'+item.FLDNM:item.FLDNM"  :class="item.NOTNULL=='Y'?'item-width2':'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="joinRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-date-picker  type="date"  v-else-if="item.EDCSS==='date'" v-model="joinRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                        <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="joinRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
                <span style="float:right">
                    <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="joinWin=false" style="margin-right:50px;">取消</el-button>
                    <el-button type="primary" size="small" icon="iconfont icon-baocun" @click="joinSave"  style="margin-right:10px;">保存</el-button>
                </span>
            </div>
        </el-dialog>
        <!--  信息编辑 -->
        <el-dialog v-dialogDrag :visible.sync="newWin" width="1300px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'black','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">第一步、发布标题列表：1）新闻与技术 & 2）观点与博客（3篇/周/人，注意：单双引号'"会保存不了！）</span>
            </div> 
            <Form :model="newRow" :label-width="120"  label-position="right" inline >  
                <span v-for="(item,index) in newCol" :key="index" >
                    <FormItem  :label="item.NOTNULL=='Y'?'*'+item.FLDNM:item.FLDNM"  :class="item.NOTNULL=='Y'?'item-width2':'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                        
                        <el-input-number   v-if="item.EDCSS==='number' "   v-model="newRow[item.FLDNO]" style="width:100%"></el-input-number>
                        <el-date-picker  type="date"  v-else-if="item.EDCSS==='date'" v-model="newRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   

                        <el-select   v-else-if="item.FLDNO==='SUBTY'" v-model="newRow[item.FLDNO]"  style="width:100%"  :value="'请选择'">
                            <el-option label="请选择" style="font-size:18px;" value=""></el-option>
                            <el-option label="1)新闻与技术" style="font-size:18px;" value="NEWS"></el-option>
                            <el-option label="2)观点与博客" style="font-size:18px;" value="BLOG"></el-option>
                        </el-select>  

                        <el-checkbox border v-else-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="newRow[item.FLDNO]" style="width:100%"></el-checkbox>  
                        <el-input :placeholder="item.PROMPT" type="textarea" v-else-if="item.EDCSS==='textarea'" :rows="7" v-model="newRow[item.FLDNO]"></el-input>
                        <el-input :placeholder="item.PROMPT" type="text" v-else :disabled="item.DISABLED==='Y'?true:false"  v-model="newRow[item.FLDNO]"></el-input>
                    </FormItem>
                </span>
                <div style="line-height:40px;height:140%;font-weight:700;color:#fff;background:#67c23a;padding-left:15px;">列表页标题缩略图上传 【大小：150x150】</div>
                <div style="display:flex">
                    <div style=" margin-top:10px;padding-left:120px;">                   
                        <el-row>
                            <el-image  :src="newRow['IMGPATH']" style="width:150px;height:150px;margin-right:30px; " >
                                <!-- <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline" style="font-size:60px"></i>
                                </div> -->
                            </el-image>
                        </el-row> 
                        <el-row > 
                            <el-link   :underline="false" @click="imgPreview('news')"><i class="iconfont icon-chazhao" style="margin-left:30px" />  </el-link>
                        </el-row>                                                                                                
                    </div>
                    <div  style="margin-left:20px;">
                        <el-row>
                            <el-link :underline="false"  >
                                <Upload
                                    ref="upnew"
                                    name="upfile"
                                    :data="uploadData"
                                    :show-upload-list="false"
                                    :before-upload="beforeImgFile"
                                    :on-success="newImgFile"
                                    :on-progress="handleProgress"
                                    :accept="imgAct"                      
                                    :format="['jpg','jpeg','png','gif','webp' ]"
                                    :max-size="1024"
                                    :on-format-error="imgFormat"
                                    :on-exceeded-size="newMaxSize"
                                    :action="uploadUrl"
                                    style="display: inline-block;width:150px; ">                           
                                    <div style="padding: 1px 0;width:60px;height:60px;">
                                        <el-image src="/assets/basedict/nopic.png" style="margin-top:30px;" ></el-image> 
                                    </div>                                                       
                                </Upload> 
                            </el-link>
                        </el-row>
                    </div> 
                </div>
            </Form>
            <div style="height:45px;line-height:140%;border-top:0px solid #ccc;padding-top:4px; ">
                <span style="display:flex;justify-content:center;">
                    <el-button type="info" size="middle" icon="iconfont icon-shanchu"  @click="newWin=false" style="margin-right:80px;">取消</el-button>
                    <el-button type="primary" size="middle" icon="iconfont icon-baocun" @click="newSave"  style="margin-right:10px;">保存</el-button>
                </span>
            </div>
        </el-dialog>
        <!--  读取excel内容 -->
        <el-dialog v-dialogDrag :visible.sync="exlWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">导入EXCEL</span>
            </div> 
            <div style="height:88vh; overflow-y:auto;"> 
                <read-excel ref="refexl" @child-exit="exitBtn" @child-save="exitBtn"></read-excel>
            </div>
            
        </el-dialog>
    </div>  
</template>
<script>
 
import tinymce from '@/components/Tinymce' 
import { getBaseData,getTabColOrData } from '../../api/user'
import {  getDate, paramData ,paramData2} from '../../api/Select' 
import {  uuid } from '../../api/Tools' 
import readExcel from './read_excel.vue' 
//局部注册
export default {
    name:"About_US",
    data () {
        return {
            selparam:'',
            oper:'like',
            txtparam:'',
            operArr:[],
            exlWin:false,
            joinWin:false,
            joinRow:{},
            newWin:false,
            newRow:{},
            activeName:'TAB4',
            menuArr:[], 
            dataCount:0,
            current:1,
            certWin:false,
            v_url:this.$store.state.queryUrl , //api请求路径    
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            promptWin:false,
            prompt:'',
            videoUploadPercent:0, 
            videoFlag:false,
            showViewer:false,
            tinymceHtml:'',
            jobHtml:'',
            newHtml:'',
            newCHtml:'', 
            v_idseq:'',
            rowSize:20,
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
            certPath:'',
            imgAct: "jpg,jpeg,png,webp,gif",
            Accept: "mp4", //上传文件格式限制
            prevPath:'',
            imgPath:'',
            allList:[],
            upFlag:'',
            tbData:[],
            knowData:[],
            joinData:[],
            joinCol:[],
            newData:[],
            newCol:[],
            selectedRow: null, //选中的行为空
            LANG:'',
            tbRow:{},
            certRow:{},
            tabCol:[],
            v_newseq:'',
            uploadData:{}, //上传数据
            isShow:true,
            videoConfig:{
                playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
                autoplay: false, // 如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [{
                    type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                    src: '' // url地址 /assets/authorize/tmp4.mp4
                }],
                poster: '', // 你的封面地址
                //width:'100%',
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true,
                    durationDisplay: true,
                    remainingTimeDisplay: false,
                    fullscreenToggle: true // 全屏按钮
                }
            },
        }
    },
    mounted () {   
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        })
        // tabs 菜单
        paramData2('IDSEQ','39084','FLAG','Y' ,'V_BS_FRONTITM').then((res)=>{     
            this.menuArr =res.data.result
        })
        paramData('IDSEQ','56567' ,'V_BS_Foot').then((res)=>{     
            this.videoConfig.sources[0].src =res.data.result[0]['VIDEOPATH']
            this.imgPath =res.data.result[0]['IMGPATH']
        })
        getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ','56567','','','v_bs_footlob','').then(res=>{
            this.tinymceHtml =res.data.result[0].note
        })
        getBaseData(this.$store.state.nologinUrl, 'idseq','45820','fty','HEAD','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.tabCol=res.data.result
        })
        getBaseData(this.$store.state.nologinUrl, 'idseq','45820','fty','HEAD2','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.joinCol=res.data.result
        })
        getBaseData(this.$store.state.nologinUrl, 'idseq','45820','fty','HEAD3','v_sys_form_fld',' order by sortby,lstseq').then(res=>{
            this.newCol=res.data.result
        })
        this.getHonList()
 
    },
    computed: {
        tabWidth: function() {
            return (window.innerWidth - 160) + 'px';
        }, 
        tabHigh: function() {
            return (window.innerHeight - 220) + 'px';
        }, 
    },
    components: {
        tinymce,
        readExcel,
    },
    created () {
        if (this.v_username){
            //
            
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }     
    },
    watch: {
        //对路由进行监 听，如果跳转的路由与当前的路由一样就将组件重新加载，避免路由加载后 富文本编辑框消失
        '$route' (to,from){
            this.isShow =false
            if(to.name==this.$route.name){
                setTimeout(() => {
                    this.isShow =true
                }, 300);
            }
        } 
    },
    methods: { 
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allList.length < this.rowSize){
                this.newData = this.allList          
            }else{
                this.newData =this.allList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }      
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.newData = this.allList.slice(_start,_end);
        },
        //状态变更
        stfgRec(row,index,val ){
            let v_comm='',v_val=val=='N'?'X':val
            if (val==='Y'){
                v_comm='审核'
            }else if (val==='N'){
                v_comm='弃审'
            }
            this.$confirm('你确定要'+v_comm+'此记录吗，审核后的记录在前端将能显现','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'bs_foot',p_cond:' set istop=\''+v_val +'\' '},
                }).then(res=>{      
                    if(res.data.code =='200')  {  
                        this.newData.splice(index,1,Object.assign(row,{ISTOP: v_val}))        
                    }else{
                        this.prompt =res.data.result
                        this.promptWin=true
                    }
                })
            }).catch(()=>{})
            
        },
        exitBtn(){
            this.exlWin =false
        },
        impExl(){
            this.exlWin =true
        },
        newClick(row, index){   //billy加多了一个Index字
            this.selectedRow = row; //billy加的
            this.v_newseq=row.IDSEQ
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getClobData' ,
                data: {p_key:'idseq',p_value:row['IDSEQ'], p_table:'v_bs_footlob' },
            }).then(res=>{ 
                this.$nextTick(()=>{
                    window.tinymce.get('refnew').setContent(res.data.result[0].note)
                    window.tinymce.get('refcn').setContent(res.data.result[0].cnote)
                })             
            })
        },
        joinClick(row){
            let that =this
            this.v_idseq=row.IDSEQ
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'auth/login/getClobData' ,
                data: {p_key:'idseq',p_value:row['IDSEQ'], p_table:'v_bs_footlob' },
            }).then(res=>{ 
                this.$nextTick(()=>{
                    window.tinymce.get('jobedit').setContent(res.data.result[0].note)
                })             
            })
        },
        addJob(){
            this.joinWin =true
            this.joinRow ={SORTBY:this.joinData.length+1,DTTM:getDate()}
        },
        addNew(){
            this.newWin =true
            this.newRow ={SORTBY:this.allList.length+1,DTTM:getDate(),SUBTY:'',ISTOP:'Y'} //原来是SUBTY:'NEWS',ISTOP:'Y'
        },
        //表格查询数据
        getHonList(){
            let that =this
            getBaseData(this.$store.state.nologinUrl, 'idty','CERT','','','v_BS_FOOT',' order by sortby,IDSEQ').then(res=>{  
                that.$nextTick(()=>{
                    that.tbData=res.data.result
                    //that.$refs['reftab'].doLayout()
                })
            }) 
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_BS_FOOT','idty','KNOW','','','','',' order by sortby,IDseq' ).then((res) => {   
                this.$nextTick(()=>{
                    this.knowData =res.data 
                    //this.$refs['refknow'].doLayout()
                })
            }) 
            this.getJoinList()
            this.getNewList()
        },
        getJoinList(){
            let that =this
            getBaseData(this.$store.state.nologinUrl, 'idty','JOIN','','','v_BS_FOOT',' order by sortby,IDSEQ').then(res=>{   
                that.joinData=res.data.result
                if (this.joinData.length>0){
                    this.v_idseq=this.joinData[0]['IDSEQ']
                    getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ',this.joinData[0]['IDSEQ'],'','','v_bs_footlob','').then(res=>{
                        this.jobHtml =res.data.result[0].note
                    })
                }
            })
        },
        getNewList(){
            let that =this
            let vm =this ,cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and upper('+this.selparam+') like upper(\'%'+this.txtparam+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam+')'+this.oper+'upper(\''+this.txtparam+'\')'
                }
            }
            getBaseData(this.$store.state.nologinUrl, 'idty','NEWS','','','v_BS_FOOT',cond_ +' order by adddttm desc').then(res=>{   
                that.allList=res.data.result
                this.initPage()
                if (this.allList.length>0){
                    this.v_newseq=this.allList[0]['IDSEQ']
                    getBaseData(this.$store.state.baseUrl+'auth/login/getClobData','IDSEQ',this.v_newseq,'','','v_bs_footlob','').then(res=>{
                        this.newHtml =res.data.result[0].note
                        this.newCHtml =res.data.result[0].cnote
                    })
                }
            })
        },
        //资质
        addCert(val){
            this.certWin =true
            this.upFlag =val
            if (val =='CERT'){
                this.tbRow={'SORTBY':this.tbData.length+1}
            }else{
                this.tbRow={'SORTBY':this.knowData.length+1}
            }
        },
        certSave(){
            if (!this.tbRow['CNAME']){
                this.promptWin=true
                this.prompt ='中文标题不能为空!!'
            }else if (!this.tbRow['ENAME']){
                this.promptWin=true
                this.prompt ='英文标题不能为空!!'
            }
            // else if (!this.tbRow['IMGPATH']){
            //     this.promptWin=true
            //     this.prompt ='请上传图片'
            // }
            else {
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_json:JSON.stringify(Object.assign(this.tbRow,{IDTY:this.upFlag})  ), p_table:'bs_fOOt',frmid:''},
                }).then(res=>{ 
                    this.getHonList()
                    this.certWin=false
                })
            }
        },
        //发布招聘
        joinSave(){
            if (!this.joinRow['CNAME']){
                this.promptWin=true
                this.prompt ='职位名称不能为空!'
            }else if (!this.joinRow['SUBNM']){
                this.promptWin=true
                this.prompt ='职位简述不能为空!'
            }else {
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_json:JSON.stringify(Object.assign(this.joinRow,{IDTY:'JOIN'})  ), p_table:'bs_fOOt',frmid:''},
                }).then(res=>{ 
                    this.getJoinList()
                    this.joinWin=false
                })
            }
        },
        //发布 信息
        newSave(){
            if (!this.newRow['SUBTY']){
                this.promptWin=true
                this.prompt ='类型不能为空!'
            }else if (!this.newRow['CNAME']){
                this.promptWin=true
                this.prompt ='标题不能为空!'
            }else if (!this.newRow['SUBNM']){
                this.promptWin=true
                this.prompt ='简述不能为空!'
            }else {
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_json:JSON.stringify(Object.assign(this.newRow,{IDTY:'NEWS'})  ), p_table:'bs_fOOt',frmid:''},
                }).then(res=>{ 
                    this.getNewList()
                    this.newWin=false
                })
            }
        },
        newEdit(row,index){
            this.newWin =true
            this.newRow =JSON.parse(JSON.stringify(row))
        },
        newDel(row,index ){
            this.$confirm('你确定要删除此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row['IDSEQ'] , p_table:'bs_foot'},
                }).then(res=>{
                    this.newData.splice(index,1)
                })   
            }).catch(()=>{})
        },
        joinEdit(row,index){
            this.joinWin =true
            this.joinRow =JSON.parse(JSON.stringify(row))
        },
        joinDel(row,index ){
            this.$confirm('你确定要删除此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row['IDSEQ'] , p_table:'bs_foot'},
                }).then(res=>{
                    this.joinData.splice(index,1)
                })   
            }).catch(()=>{})
        },
        tbEdit(row,val){
            this.certWin =true
            this.upFlag =val
            this.tbRow =JSON.parse(JSON.stringify(row))
        },
        tbDel(row,index,val){
            this.$confirm('你确定要删除此行记录吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                this.$axios({   
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                    data: {p_key:'idseq',p_value:row['IDSEQ'] , p_table:'bs_foot'},
                }).then(res=>{
                    if (val =='CERT'){
                        this.tbData.splice(index,1)
                    }else{
                        this.knowData.splice(index,1)
                    }
                })   
            }).catch(()=>{})
        },
        //保存内容
        saveRec(){
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'sysprivs/tableLobSave?username='+this.v_username+'&password='+this.v_password,
                data: {p_idseq:'56567' ,p_note:this.tinymceHtml,p_table:'bs_foot',p_key:'note'},
            }).then(res=>{   
                if(res.data.result =='OK')  {          
                    this.$Message.info('已保存')
                }else{
                    this.$alert(res.data.result, '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        type:'info'
                    })
                }        
            }) 
        },
        //保存信息内容
        newRec(){ //大对象保存
            if (this.v_newseq){
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/tableLobSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_idseq:this.v_newseq ,p_note:this.newHtml,p_table:'bs_foot',p_key:'note'},
                }).then(res=>{   
                    if(res.data.result =='OK')  {          
                        this.$Message.info('已保存')
                    }else{
                        this.prompt =res.data.result
                        this.promptWin=true
                    }        
                }) 
            }else{
                this.promptWin=true
                this.prompt='请选中上面表格的行记录'
            }
        },
        newCN(){ //大对象保存
            if (this.v_newseq){
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/tableLobSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_idseq:this.v_newseq ,p_note:this.newCHtml,p_table:'bs_foot',p_key:'cnote'},
                }).then(res=>{   
                    if(res.data.result =='OK')  {          
                        this.$Message.info('已保存')
                    }else{
                        this.prompt =res.data.result
                        this.promptWin=true
                    }        
                }) 
            }else{
                this.promptWin=true
                this.prompt='请选中上面表格的行记录'
            }
        },
        jobRec(){ //大对象保存
            if (this.v_idseq){
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/tableLobSave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_idseq:this.v_idseq ,p_note:this.jobHtml,p_table:'bs_foot',p_key:'note'},
                }).then(res=>{   
                    if(res.data.result =='OK')  {          
                        this.$Message.info('已保存')
                    }else{
                        this.$alert(res.data.result, '提示', {
                            confirmButtonText: '确定',
                            showClose:false,
                            type:'info'
                        })
                    }        
                }) 
            }else{
                this.promptWin=true
                this.prompt='请选中上面表格的行记录'
            }
        },
        //文件超出指定文件大小限制的钩子
        videoMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出100M'
            this.promptWin=true
        },
        imgMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出4M'
            this.promptWin=true
        },
        newMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出1M'
            this.promptWin=true
        },
        //文件格式验证错误时的钩子
        videoFormat (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 mp4' 
            this.promptWin=true
        }, 
        imgFormat (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择 jpg,jpeg,png' 
            this.promptWin=true
        }, 
        // 文件上传成功时的钩子
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            //https://kreco.oss-accelerate.aliyuncs.com/manage/file/product/richie968220220928152456.mp4
            let v_path =res.result
            this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                method: 'post',
                url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                data: {p_key:'idseq',p_value:'56567', p_table:'v_bs_foot',p_cond:' set videopath=\''+v_path+'\''},
            }).then(res=>{ 
                if (res.data.code==='200'){
                    this.videoConfig.sources[0].src =v_path
                }else{
                    this.promptWin=true
                    this.prompt =res.data.result
                }
            })               
        },
        certImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.tbRow['IMGPATH'] =res.result
        },
        newImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.newRow['IMGPATH'] =res.result
        },
        okImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            let v_path =res.result
            this.$axios({  //传入 p_frmid 参数是为了生成 表单 的编码
                method: 'post',
                url:this.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                data: {p_key:'idseq',p_value:'56567', p_table:'v_bs_foot',p_cond:' set imgpath=\''+v_path+'\''},
            }).then(res=>{ 
                if (res.data.code==='200'){
                    this.imgPath =v_path
                }else{
                    this.promptWin=true
                    this.prompt =res.data.result
                }
            })               
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {  
            this.uploadData = {
                p_user:this.v_username,
                p_idseq:  uuid(),
                p_table:'',
                p_filety:'',
            }
            let promise = new Promise(resolve => {
                this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        imgPreview(val){
            if (val=='cert'){
                this.prevPath=this.tbRow['IMGPATH']
            }else if (val=='news'){
                this.prevPath=this.newRow['IMGPATH']
            } 
            this.showViewer=true
        },
        getRowKey(row){
            return row.IDSEQ //获取行键的办法
        },
    }
}
</script>
<style lang="less">
 
    .el-dialog__body{
        padding: 10px 40px 20px 40px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:0px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
</style>
<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .imgSize{
       width:280px;
       height:260px;     
    }
    .imgSize:hover{
        transform:scale(1.3);
    }
    .knowSize{
       width:276px;
       height:340px;     
    }
    .knowSize:hover{
        transform:scale(1.3);
    }
    .img-div{
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
        line-height:40px;
        background: linear-gradient(35deg, #CCFFFF, #afacac);
        text-align: center;
        font-size:14px;
        font-weight: 600;
    }
    .know-div{
        text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
        overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
        white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
        height:40px;
        line-height:40px;
        background: #409eff;
        text-align: center;
        font-size:14px;
        font-weight: 600;
        color:white;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
 
    .video-small {
        width: 1260px;
        height: 600px;
        object-fit: fill;
        overflow:hidden;
        margin-top:10px;
        border-radius: 5px;
    }
    .item-width  {
        width: 48%;
    }
    .item-width2  {
        width: 48%;
        background:#f5cdd7 !important;
        font-weight:700;
        color:white;
    }
 
</style>
 