<template>
  <div>
    <div style="line-height:32px;height:35px;border-bottom:2px solid #389;display:flex">
      <el-upload
        :action="uploadUrl"
        :on-success="onSuccess"
        :file-list="fileList"
        :accept="imgAct" 
        :before-upload="beforeUpload">
        <el-button size="small" type="primary" style="height:30px;width:100px;">上传EXCEL</el-button>
        <div slot="tip" class="el-upload-tip">【 提示：只能上传xlsx文件，且不超过4MB 】 
          <el-select   v-model="typeVal" style="height:30px;width:110px;margin-left:10px" size="small" >
              <el-option label="信息发布" value="NEWS"></el-option>
              <el-option label="博客" value="BLOG"></el-option>
          </el-select> 
        </div>
      </el-upload>
    </div>
    <el-table  :data="tableData[0]" 
        border                            
        size="small"
        :height="tabHeight" 
   
        ref="refnew"
        highlight-current-row
        style="overflow:auto;margin-top:1px;">
      <el-table-column  v-for="(item, index) in tableHead" :key="index"
          :prop="item"
          :label="item"
          width="180">
        <template slot="header"  slot-scope="scope">

           <el-select v-model="headObj[scope.$index]" plain size="small" style="width:170px; " >
              <span v-for="(item,index) in tabCol" :key='index'>
                  <el-option :label="item.title" :value="item.key" v-if="item.key!=='SUBTYNM'&&item.key!=='ADDUSER'&&item.key!=='ADDDTTM'&&item.key!=='SORTBY'&&item.key!=='SUBTY'&&item.key!=='ISOK'"></el-option>
              </span>
           </el-select>
           <div>{{scope.column.label}}</div>
        </template>
      </el-table-column>
    </el-table>
    <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
        <span style="float:right">
            <el-button type="info" size="small" icon="iconfont icon-shanchu"  @click="exitBtn" style="margin-right:50px;">取消</el-button>
            <el-button type="primary" size="small" icon="iconfont icon-baocun" @click="saveRec"  style="margin-right:10px;">保存</el-button>
        </span>
    </div>
    <!-- 提示框 -->
    <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
        <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
            <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
        </div>
        <div style=" margin:15px 10px">{{prompt}}</div>
        <div slot="footer">
            <el-button type="primary" size="small"  @click="promptWin=false" style="margin-right:20px;height:32px">确定</el-button>
        </div>
    </el-dialog>
    <el-dialog :visible.sync="loadWin" width="50px" append-to-body :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
        <el-image src="assets/authorize/loading.gif"  ></el-image>   
    </el-dialog>
  </div>
</template>
<script>
 
import { read, utils } from "xlsx"
import { getTabColOrData,getBaseData } from '../../api/user' 
import {  uuid } from '../../api/Tools'  
import {  getDate } from '../../api/Select' 
export default {
  data() {
    return {
      typeVal:'NEWS',
      loadWin:false,
      promptWin:false,
      prompt:'',
      uploadData:{}, //上传数据
      fileList: [], //上传文件列表
      tableHead: [], //表头
      tableData: [], // 表数据
      tabCol:[],
      headObj:{},
      v_url:this.$store.state.baseUrl+'sysprivs/getTableRecMany', //api请求路径
      v_username:this.$cookies.get('v_username'), //api 请求用户
      v_password:this.$cookies.get('v_password') ,//api 请求密码
      imgAct: "xlsx",
      uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
    };
  },
  computed: {
      tabHeight: function() {
          return (window.innerHeight - 200) + 'px';
      }, 
    },
  mounted () {
     this.getTabField()
  },
  methods: {
    saveRec(){  
      this.$confirm('请在保存前匹配好对应字段，要继续吗?','提示',{
          confirmButtonText: '确定',
          cancelButtonText:'取消',
          customClass:'messageBox',
          showClose:false,
          type:'info',
      }).then(()=>{ 
          let mapData=[],v_bool=false
          this.tableData[0].forEach(item=>{
            let obj_={},i=0
            for (var key in item){   
              obj_[this.headObj[i]] =item[key]
              if (!this.headObj[i]){
                v_bool =true
              }
              i++
            }
            mapData.push(obj_)
          })
          if (v_bool){
            this.promptWin =true
            this.prompt='请匹配字段名称'
          }else{
            var k=0
            mapData.forEach(item=>{
              k++
              let v_note =item['EINFO'] ,v_cnote =item['CINFO']
              //分记录保存
              this.$axios({
                  method: 'post',
                  url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                  data: {p_json:JSON.stringify(Object.assign(item,{IDTY:'NEWS',SUBTY:this.typeVal,ISTOP:'X',DTTM:getDate()})  ), p_table:'bs_fOOt',frmid:''}
              }).then(res=>{ 
                  let v_idseq =res.data.idseq
                  if(res.data.code=='200'){
                    this.$axios({  //更新中文大对象
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/tableLobSave?username='+this.v_username+'&password='+this.v_password,
                        data: {p_idseq:v_idseq ,p_note:v_cnote,p_table:'bs_foot',p_key:'cnote'},
                    }).then(res=>{ 
                      //console.log(v_idseq,v_cnote)  
                    })
                    this.$axios({  //更新英文大对象
                        method: 'post',
                        url:this.$store.state.baseUrl+'sysprivs/tableLobSave?username='+this.v_username+'&password='+this.v_password,
                        data: {p_idseq:v_idseq ,p_note:v_note,p_table:'bs_foot',p_key:'note'},
                    }).then(res=>{   
                        //console.log(v_idseq,v_note)  
                    })
                  }
              })
            })
            if (k ==mapData.length){
              this.$emit('child-save')
            }
          }
      }).catch(()=>{})
    },
    exitBtn(){
      this.$emit('child-exit')
    },
    //上传文件时的判断类型
    beforeUpload(file){
      var testmsg =file.name.substring(file.name.lastIndexOf('.')+1)
      // .jpg ,.xlsx
      const extension1 =testmsg ==='xlsx'
      //const extension2 =testmsg ==='png'
      if (! extension1){
        this.prompt= '文件格式错误,'+file.name + ' 不正确,请选择 xlsx格式' 
        this.promptWin=true
        return false
      }else{
        return true
      }
    },
    newMaxSize (file) {
        this.prompt =file.name + ' 文件太大, 不能超出4M'
        this.promptWin=true
    },
    imgFormat (err,file) {
      console.log('error:'+err)
        this.prompt=err +'文件格式错误,'+file.name + ' 不正确,请选择 xlsx格式' 
        this.promptWin=true
    }, 
    //上传文件之前的钩子
    beforeImgFile (res, file) {  
        this.uploadData = {
            p_user:this.v_username,
            p_idseq:  uuid(),
            p_table:'',
            p_filety:'',
        }
        let promise = new Promise(resolve => {
            this.$nextTick(function() {
                resolve(true);
            });
        });
        return promise;  
    },
    //获取表列头字段
    getTabField(){
        var vm=this
        getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','45820','fty','HEAD3','','',' order by sortby,lstseq').then((res) => {
            for (let k=0; k<res.data.length; k++){
                vm.tabCol.push({ //向数组的开头 添加序列号
                    title: res.data[k].title,
                    key: res.data[k].key,
                    align: res.data[k].align,
                    width: res.data[k].width,
                    thide: res.data[k].THIDE,
                    edcss:res.data[k].EDCSS,
                })
            }            
        }) 
    },
    onChange(e) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
 
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = read(data, { type: "binary" });
          const params = [];
          // 取对应表生成json表格内容
          workbook.SheetNames.forEach(item => {
            params.push({
              name: item,
              dataList: utils.sheet_to_json(workbook.Sheets[item])
            });
            this.tableData.push(utils.sheet_to_json(workbook.Sheets[item]));
          })
          // 该算法仅针对表头无合并的情况
          if (this.tableData.length > 0) {
            // 获取excel中第一个表格数据tableData[0][0]，并且将表头提取出来
            for (const key in this.tableData[0][0]) {
              this.tableHead.push(key);
            }
          }
          
          return params;
          // 重写数据
        } catch (e) {
          console.log("error:" + e);
          return false;
        }
      };
      fileReader.readAsBinaryString(file);
    },
    onSuccess(res, file, fileList) {
      this.loadWin =true
      // 将文件放入
      for (let i = 0; i < fileList.length; i++) {
        if (file.name != fileList[i].name) {
          this.fileList.push({
            name: file.name,
            url: "",
            uid: file.uid
          });
        }
      }
 
      // this.fileList = fileList.slice(-3);
      const files = { 0: file };
      this.readExcel(files);
    },
    readExcel(file) {
      const fileReader = new FileReader();
 
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = read(data, { type: "binary" });
          const params = [];
          // 取对应表生成json表格内容
          workbook.SheetNames.forEach(item => {
            params.push({
              name: item,
              dataList: utils.sheet_to_json(workbook.Sheets[item])
            });
            this.tableData.push(utils.sheet_to_json(workbook.Sheets[item]));
          });
          // 该算法仅针对表头无合并的情况
          if (this.tableData.length > 0) {
            // 获取excel中第一个表格数据tableData[0][0]，并且将表头提取出来
            for (const key in this.tableData[0][0]) {
              this.tableHead.push(key);
            }
          }
          return params;
          // 重写数据
        } catch (e) {
          console.log("error:" + e);
          return false;
        }
      }
      fileReader.readAsBinaryString(file[0].raw);
      this.loadWin=false
    }
  }
};
</script>
<style lang="scss" scoped>
  .el-upload-tip {
      display: inline;
      font-size: 12px;
    }
 
</style>